import React, { useState, useEffect, useContext, useMemo} from 'react';
import { UserContext } from '../../../contexts/UserContext';
import withRecaptcha from '../../../HOC/withRecaptcha';
import './ComunasTarifas.css';

// Componente de Listado de Promociones
// Componente de Listado de Promociones con Paginación
const PromotionsList = ({ token, promotions }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  // Filtrar y paginar promociones
  const filteredPromotions = useMemo(() => {
    return promotions.filter(promotion => 
      promotion.promotion.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, promotions]);

  const paginatedPromotions = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredPromotions.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredPromotions, currentPage]);

  const totalPages = Math.ceil(filteredPromotions.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="promotions-list-wrapper">
      <div className="promotions-list-search">
        <input 
          type="text" 
          placeholder="Buscar promoción..." 
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setCurrentPage(1);
          }}
          className="promotions-search-input"
        />
      </div>
      <div className="promotions-list-table-container">
        <table className="promotions-list-table">
          <thead>
            <tr>
              {/* <th>ID</th> */}
              <th>Nombre</th>
              <th>Monto Instalación</th>
              {/* <th>Estado</th> */}
            </tr>
          </thead>
          <tbody>
            {paginatedPromotions.map(promotion => (
              <tr key={promotion.promotion_id}>
                {/* <td>{promotion.promotion_id}</td> */}
                <td>{promotion.promotion}</td>
                <td>{promotion.amount}</td>
                {/* <td>{promotion.is_active ? 'Activo' : 'Inactivo'}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="promotions-list-pagination">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
          <button
            key={page}
            className={`promotions-pagination-button ${currentPage === page ? 'active' : ''}`}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        ))}
      </div>
    </div>
  );
};

// Utility function for API calls
const apiCall = async (url, method = 'GET', body = null, token = null) => {
  const headers = {
    'Content-Type': 'application/json',
    ...(token && { 'Authorization': `Bearer ${token}` })
  };
  const config = {
    method,
    headers,
    ...(body && { body: JSON.stringify(body) })
  };
  const response = await fetch(url, config);
  if (!response.ok) throw new Error('API call failed');
  return response.json();
};

// Reusable select component
const Select = ({ value, onChange, options, placeholder }) => (
  <select 
    value={value}
    className="comunas-tarifas-field"
    onChange={(e) => {
      onChange(e.target.value);
      e.stopPropagation();
    }} 
    onClick={(e) => e.stopPropagation()}
    required
  >
    <option value="">{placeholder}</option>
    {options.map(option => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
);

// CreatePromotion component
const CreatePromotion = ({ token, installationAmounts, onSubmitWithRecaptcha }) => {
  const [promotionName, setPromotionName] = useState('');
  const [selectedInstallationAmountId, setSelectedInstallationAmountId] = useState('');

  const handleCreatePromotion = async (event) => {
    event.preventDefault();
    if (!promotionName || !selectedInstallationAmountId) {
      alert('Por favor complete todos los campos.');
      return;
    }

    const submitForm = async (event, recaptchaToken) => {
      try {
        const result = await apiCall(
          `${process.env.REACT_APP_API_URL}/promotions`, 
          'POST', 
          {
            promotion: promotionName,
            installationAmountId: selectedInstallationAmountId,
            recaptchaToken
          }, 
          token
        );
        alert(`Promoción creada: ${result.message}`);
        setPromotionName('');
        setSelectedInstallationAmountId('');
        window.location.reload();
      } catch (error) {
        alert('Error al crear la promoción');
      }
    };

    await onSubmitWithRecaptcha(event, submitForm);
  };

  return (
    <form className="comunas-tarifas-form" onSubmit={handleCreatePromotion}>
      <div className="comunas-tarifas-field-group">
        <label htmlFor="promotionName">
          Nombre de la Promoción:
          <input
            id="promotionName"
            name="promotionName"
            className="comunas-tarifas-field"
            type="text"
            value={promotionName}
            onChange={(event) => setPromotionName(event.target.value)}
            required
          />
        </label>
      </div>
      <div className="comunas-tarifas-field-group">
        <label>Monto de Instalación:</label>
        <Select
          className="comunas-tarifas-field"
          value={selectedInstallationAmountId}
          onChange={setSelectedInstallationAmountId}
          options={installationAmounts.map((amount) => ({
            value: amount.installation_amount_id,
            label: amount.amount,
          }))}
          placeholder="Seleccione un monto"
        />
      </div>
      <button className="comunas-tarifas-submit-button" type="submit">
        Crear Promoción
      </button>
    </form>
  );
};

// UpdatePromotion component
const UpdatePromotion = ({ token, promotions, installationAmounts, onSubmitWithRecaptcha }) => {
  const [selectedPromotionId, setSelectedPromotionId] = useState('');
  const [updatedPromotionName, setUpdatedPromotionName] = useState('');
  const [updatedInstallationAmountId, setUpdatedInstallationAmountId] = useState('');

  const handleUpdatePromotion = async (e) => {
    e.preventDefault();
    if (!selectedPromotionId || !updatedPromotionName || !updatedInstallationAmountId) {
      alert('Por favor complete todos los campos.');
      return;
    }

    const submitForm = async (event, recaptchaToken) => {
      try {
        const result = await apiCall(
          `${process.env.REACT_APP_API_URL}/promotions/${selectedPromotionId}`, 
          'PATCH', 
          {
            promotion: updatedPromotionName,
            installation_amount_id: updatedInstallationAmountId,
            recaptchaToken
          }, 
          token
        );
        alert('Promoción actualizada: ' + result.message);
        setUpdatedPromotionName('');
        setUpdatedInstallationAmountId('');
        setSelectedPromotionId('');
        window.location.reload();
      } catch (error) {
        alert('Error al actualizar la promoción');
      }
    };

    await onSubmitWithRecaptcha(e, submitForm);
  };

  return (
    <form className="comunas-tarifas-form" onSubmit={handleUpdatePromotion}>
      <div className="comunas-tarifas-field-group">
        <label>Seleccionar Promoción:</label>
        <Select
          className="comunas-tarifas-field"
          value={selectedPromotionId}
          onChange={setSelectedPromotionId}
          options={promotions.map(promotion => ({
            value: promotion.promotion_id,
            label: promotion.promotion
          }))}
          placeholder="Seleccione una promoción"
        />
      </div>
      <div className="comunas-tarifas-field-group">
        <label htmlFor='updatedPromotionName'>Nombre de la Promoción:
          <input
            id="updatedPromotionName"
            name="updatedPromotionName"
            className="comunas-tarifas-field"
            type="text"
            value={updatedPromotionName}
            onChange={(e) => setUpdatedPromotionName(e.target.value)}
            required
          />
        </label>
      </div>
      <div className="comunas-tarifas-field-group">
        <label>Monto de Instalación:</label>
        <Select
          className="comunas-tarifas-field"
          value={updatedInstallationAmountId}
          onChange={setUpdatedInstallationAmountId}
          options={installationAmounts.map(amount => ({
            value: amount.installation_amount_id,
            label: amount.amount
          }))}
          placeholder="Seleccione un monto"
        />
      </div>
      <button className="comunas-tarifas-submit-button" type="submit">
        Actualizar Promoción
      </button>
    </form>
  );  
};

// //Deshabilitar
// const DisablePromotions = ({ token, regions, promotions }) => {
//   const [selectedRegionId, setSelectedRegionId] = useState('');
//   const [communes, setCommunes] = useState([]);
//   const [selectedCommuneId, setSelectedCommuneId] = useState('');
//   const [selectedPromotionIds, setSelectedPromotionIds] = useState([]);
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   useEffect(() => {
//     if (selectedRegionId) {
//       // Cargar comunas para la región seleccionada
//       apiCall(`${process.env.REACT_APP_API_URL}/communes/communes/${selectedRegionId}`,'GET', null, token)
//         .then(setCommunes)
//         .catch(error => console.error('Error al cargar comunas:', error));
//     }
//   }, [selectedRegionId, token]);

//   const handleRegionChange = (event) => {
//     const regionId = event.target.value; // Obtener el ID de la región seleccionada
//     setSelectedRegionId(regionId);  // Actualizar el estado de selectedRegionId
//     setSelectedCommuneId('');  // Limpiar la comuna seleccionada al cambiar la región
//   };

//   const handleCommuneChange = (event) => {
//     setSelectedCommuneId(event.target.value); // Obtener el ID de la comuna seleccionada
//   };

//   const handlePromotionChange = (promotionId, isChecked) => {
//     setSelectedPromotionIds((prev) =>
//       isChecked ? [...prev, promotionId] : prev.filter((id) => id !== promotionId)
//     );
//   };

//   const handleDisablePromotions = async (e) => {
//     e.preventDefault();
//     if (selectedPromotionIds.length === 0 || !selectedCommuneId) {
//       alert('Debe seleccionar al menos una promoción y una comuna.');
//       return;
//     }
//     setIsSubmitting(true);
//     try {
//       const result = await apiCall(`${process.env.REACT_APP_API_URL}/promotions/communes/${selectedCommuneId}/promotions/disable`, 'PATCH', {
//         promotionIds: selectedPromotionIds,
//       }, token);
//       alert(result.message);
//       setIsSubmitting(false);
//       window.location.reload();
//     } catch (error) {
//       console.error('Error al deshabilitar promociones:', error);
//       alert('Error al deshabilitar promociones');
//       setIsSubmitting(false);
//     }
//   };

//   return (
//     <form className="comunas-tarifas-form" onSubmit={handleDisablePromotions}>
//       <h3 className="comunas-tarifas-header">Seleccionar Región:</h3>
//       <select
//         className="comunas-tarifas-field"
//         value={selectedRegionId}
//         onChange={handleRegionChange}
//       >
//         <option value="">Seleccione una región</option>
//         {regions.map((region) => (
//           <option key={region.region_id} value={region.region_id}>
//             {region.region_name}
//           </option>
//         ))}
//       </select>

//       <h3 className="comunas-tarifas-header">Seleccionar Comuna:</h3>
//       <select
//         className="comunas-tarifas-field"
//         value={selectedCommuneId}
//         onChange={handleCommuneChange}
//       >
//         <option value="">Seleccione una comuna</option>
//         {/* Ya no es necesario el filtro aquí */}
//         {communes.map((commune) => (
//           <option key={commune.commune_id} value={commune.commune_id}>
//             {commune.commune_name}
//           </option>
//         ))}
//       </select>

//       <h3 className="comunas-tarifas-header">Seleccionar Promociones:</h3>
//       {promotions.map((promotion) => (
//         <div key={promotion.promotion_id} className="comunas-tarifas-field-group">
//           <input
//             className="comunas-tarifas-field"
//             type="checkbox"
//             id={`promotion-${promotion.promotion_id}`}
//             value={promotion.promotion_id}
//             onChange={(e) => handlePromotionChange(promotion.promotion_id, e.target.checked)}
//           />
//           <label htmlFor={`promotion-${promotion.promotion_id}`} className="comunas-tarifas-field-group">
//             {promotion.promotion}
//           </label>
//         </div>
//       ))}

//       <button className="comunas-tarifas-submit-button" type="submit" disabled={isSubmitting}>
//         Deshabilitar Promociones
//       </button>
//     </form>
//   );
// };


// const AssignPromotion = ({ token, regions, promotions }) => {
//   const [selectedRegionId, setSelectedRegionId] = useState('');
//   const [communes, setCommunes] = useState([]);
//   const [selectedCommuneId, setSelectedCommuneId] = useState('');
//   const [selectedPromotionIds, setSelectedPromotionIds] = useState([]);

//   useEffect(() => {
//     if (selectedRegionId) {
//       // Cargar las comunas para la región seleccionada
//       apiCall(`${process.env.REACT_APP_API_URL}/communes/communes/${selectedRegionId}`,'GET', null, token)
//         .then(setCommunes)
//         .catch(error => console.error('Error al cargar las comunas:', error));
//     } else {
//       setCommunes([]); // Limpiar las comunas si no hay región seleccionada
//     }
//   }, [selectedRegionId, token]);

//   const handleRegionChange = (event) => {
//     setSelectedRegionId(event.target.value); // Guardar el ID de la región seleccionada
//     setSelectedCommuneId(''); // Limpiar la comuna seleccionada
//   };

//   const handleCommuneChange = (event) => {
//     setSelectedCommuneId(event.target.value); // Guardar el ID de la comuna seleccionada
//   };

//   const handlePromotionChange = (promotionId, isChecked) => {
//     setSelectedPromotionIds((prev) =>
//       isChecked ? [...prev, promotionId] : prev.filter((id) => id !== promotionId)
//     );
//   };

//   const handleAssignPromotionToCommune = async (e) => {
//     e.preventDefault();
//     if (selectedPromotionIds.length === 0 || !selectedCommuneId) {
//       alert('Debe seleccionar al menos una comuna y una promoción.');
//       return;
//     }
//     try {
//       const result = await apiCall(`${process.env.REACT_APP_API_URL}/promotions/communes/${selectedCommuneId}/promotions`, 'POST', {
//         promotionIds: selectedPromotionIds,
//       }, token);
//       alert('Promoción asignada exitosamente:',result.message);
//       window.location.reload();
//     } catch (error) {
//       console.error('Error al asignar la promoción a la comuna:', error);
//       alert('Error al asignar la promoción a la comuna');
//     }
//   };

//   return (
//     <form onSubmit={handleAssignPromotionToCommune}>
//       <h3 className="comunas-tarifas-header">Seleccionar Región:</h3>
//       <select
//         className="comunas-tarifas-field"
//         value={selectedRegionId}
//         onChange={handleRegionChange}
//       >
//         <option value="">Seleccione una región</option>
//         {regions.map(region => (
//           <option key={region.region_id} value={region.region_id}>
//             {region.region_name}
//           </option>
//         ))}
//       </select>

//       <h3 className="comunas-tarifas-header">Seleccionar Comuna:</h3>
//       <select
//         className="comunas-tarifas-field"
//         value={selectedCommuneId}
//         onChange={handleCommuneChange}
//         disabled={!selectedRegionId}
//       >
//         <option value="">Seleccione una comuna</option>
//         {communes.map(commune => (
//           <option key={commune.commune_id} value={commune.commune_id}>
//             {commune.commune_name}
//           </option>
//         ))}
//       </select>

//       <h3 className="comunas-tarifas-header">Seleccionar Promociones:</h3>
//       {promotions.map((promotion) => (
//         <div key={promotion.promotion_id} className="comunas-tarifas-field-group">
//           <input
//             className="comunas-tarifas-field"
//             type="checkbox"
//             id={`promotion-${promotion.promotion_id}`}
//             value={promotion.promotion_id}
//             onChange={(e) => handlePromotionChange(promotion.promotion_id, e.target.checked)}
//           />
//           <label htmlFor={`promotion-${promotion.promotion_id}`} className="comunas-tarifas-field-group">
//             {promotion.promotion}
//           </label>
//         </div>
//       ))}

//       {/* <button className="comunas-tarifas-submit-button" type="submit">
//         Asignar Promoción
//       </button> */}
//     </form>
//   );
// };



const UpdateInstallationAmount = ({ token, promotions, installationAmounts }) => {
  const [selectedPromotionId, setSelectedPromotionId] = useState('');
  const [installationAmountId, setInstallationAmountId] = useState('');

  const handlePromotionChange = (promotionId) => {
    setSelectedPromotionId(promotionId);
  };

  const handleInstallationAmountChange = (installationAmountId) => {
    setInstallationAmountId(installationAmountId);
  };

  const handleUpdateInstallationAmount = async (e) => {
    e.preventDefault();
    if (!selectedPromotionId || !installationAmountId) {
      alert('Debe seleccionar una promoción y un monto de instalación.');
      return;
    }
    try {
      const result = await apiCall(`${process.env.REACT_APP_API_URL}/promotions/promotions/${selectedPromotionId}/installation-amount`, 'PATCH', {
        installation_amount_id: installationAmountId,
      }, token);
      alert(result.message);
      window.location.reload();
    } catch (error) {
      console.error('Error al actualizar el monto de instalación:', error);
      alert('Error al actualizar el monto de instalación');
    }
  };

  return (
    <form className="comunas-tarifas-form" onSubmit={handleUpdateInstallationAmount}>
      <div className="comunas-tarifas-field-group">
        <label className="comunas-tarifas-field-group strong">Seleccionar Promoción:</label>
        <Select
          className="comunas-tarifas-field"
          value={selectedPromotionId}
          onChange={handlePromotionChange}
          options={promotions.map(promotion => ({
            value: promotion.promotion_id,
            label: promotion.promotion
          }))}
          placeholder="Seleccione una promoción"
        />
      </div>
      <div className="comunas-tarifas-field-group">
        <label className="comunas-tarifas-field-group strong">Monto de Instalación:</label>
        <Select
          className="comunas-tarifas-field"
          value={installationAmountId}
          onChange={handleInstallationAmountChange}
          options={installationAmounts.map(amount => ({
            value: amount.installation_amount_id,
            label: amount.amount
          }))}
          placeholder="Seleccione un monto"
        />
      </div>
      <button className="comunas-tarifas-submit-button" type="submit">Actualizar Monto</button>
    </form>
  );  
};

// Crear Monto de Instalación
const CreateInstallationAmount = ({ token, onSubmitWithRecaptcha }) => {
  const [amount, setAmount] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!amount) {
      alert('Por favor ingrese un monto.');
      return;
    }

    const submitForm = async (event, recaptchaToken) => {
      try {
        const result = await apiCall(
          `${process.env.REACT_APP_API_URL}/promotions/installation-amounts/create`,
          'POST',
          {
            amount,
            recaptchaToken
          },
          token
        );
        alert('Monto de instalación creado: ' + result.message);
        setAmount('');
        window.location.reload();
      } catch (error) {
        alert('Error al crear el monto de instalación');
      }
    };

    await onSubmitWithRecaptcha(event, submitForm);
  };

  return (
    <form className="comunas-tarifas-form" onSubmit={handleSubmit}>
      <div className="comunas-tarifas-field-group">
        <label htmlFor="amount">
          Monto de Instalación:
          <input
            id="amount"
            type="text"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
            className="comunas-tarifas-field"
          />
        </label>
      </div>
      <button type="submit" className="comunas-tarifas-submit-button">
        Crear Monto
      </button>
    </form>
  );
};

// Modificar Monto de Instalación
const ModifyInstallationAmount = ({ token, installationAmounts, onSubmitWithRecaptcha }) => {
  const [selectedAmountId, setSelectedAmountId] = useState('');
  const [newAmount, setNewAmount] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedAmountId || !newAmount) {
      alert('Por favor complete todos los campos.');
      return;
    }

    const submitForm = async (event, recaptchaToken) => {
      try {
        const result = await apiCall(
          `${process.env.REACT_APP_API_URL}/promotions/installation-amounts/${selectedAmountId}`,
          'PUT',
          {
            amount: newAmount,
            recaptchaToken
          },
          token
        );
        alert('Monto actualizado: ' + result.message);
        setSelectedAmountId('');
        setNewAmount('');
        window.location.reload();
      } catch (error) {
        alert('Error al actualizar el monto');
      }
    };

    await onSubmitWithRecaptcha(event, submitForm);
  };

  return (
    <form className="comunas-tarifas-form" onSubmit={handleSubmit}>
      <div className="comunas-tarifas-field-group">
        <label>Seleccionar Monto:</label>
        <Select
          value={selectedAmountId}
          onChange={setSelectedAmountId}
          options={installationAmounts.map(amount => ({
            value: amount.installation_amount_id,
            label: amount.amount
          }))}
          placeholder="Seleccione un monto"
        />
      </div>
      <div className="comunas-tarifas-field-group">
        <label>Nuevo Monto:</label>
        <input
          type="text"
          value={newAmount}
          onChange={(e) => setNewAmount(e.target.value)}
          required
          className="comunas-tarifas-field"
        />
      </div>
      <button type="submit" className="comunas-tarifas-submit-button">
        Actualizar Monto
      </button>
    </form>
  );
};

// Habilitar/Deshabilitar Monto de Instalación
const ToggleInstallationAmount = ({ token, installationAmounts }) => {
  const [selectedAmountId, setSelectedAmountId] = useState('');
  const [isActive, setIsActive] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedAmountId || isActive === '') {
      alert('Por favor seleccione un monto y especifique el estado.');
      return;
    }
    
    setIsSubmitting(true);
    try {
      const result = await apiCall(
        `${process.env.REACT_APP_API_URL}/promotions/installation-amounts/${selectedAmountId}/toggle`,
        'PATCH',
        {
          is_active: isActive === '1'
        },
        token
      );
      alert('Monto actualizado: ' + result.message);
      setSelectedAmountId('');
      setIsActive('');
      setIsSubmitting(false);
      window.location.reload();
    } catch (error) {
      console.error('Error al actualizar el monto:', error);
      alert('Error al actualizar el monto');
      setIsSubmitting(false);
    }
  };

  return (
    <form className="comunas-tarifas-form" onSubmit={handleSubmit}>
      <div className="comunas-tarifas-field-group">
        <label>Seleccionar Monto:</label>
        <select
          className="comunas-tarifas-field"
          value={selectedAmountId}
          onChange={(e) => {
            setSelectedAmountId(e.target.value);
          }}
        >
          <option value="">Seleccione un monto</option>
          {installationAmounts.map(amount => (
            <option key={amount.installation_amount_id} value={amount.installation_amount_id}>
              {amount.amount} (Estado: {amount.is_active === 1 ? 'Habilitado' : 'Deshabilitado'})
            </option>
          ))}
        </select>
      </div>
      <div className="comunas-tarifas-field-group">
        <label>Estado:</label>
        <select
          className="comunas-tarifas-field"
          value={isActive} 
          onChange={(e) => setIsActive(e.target.value)}
        >
          <option value="">Seleccione el estado</option>
          <option value="1">Habilitar</option>
          <option value="0">Deshabilitar</option>
        </select>
      </div>
      <button type="submit" className="comunas-tarifas-submit-button" disabled={isSubmitting}>
        Actualizar Estado
      </button>
    </form>
  );
};

// Componente principal de Tarifas
const Tarifas = () => {
  const { token } = useContext(UserContext);
  const [regions, setRegions] = useState([]);
  const [installationAmounts, setInstallationAmounts] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [activeTab, setActiveTab] = useState('list');

  // Tabs para navegación
  const tabs = [
    { value: 'list', label: 'Ver Promociones' },
    { value: 'promotions', label: 'Gestión de Promociones' },
    { value: 'installation', label: 'Montos Instalación' }
  ];

  // Cargar datos iniciales
  useEffect(() => {
    if (!token) return;
    const fetchData = async () => {
      try {
        const [regionsData, installationAmountsData, promotionsData] = await Promise.all([
          apiCall(`${process.env.REACT_APP_API_URL}/regions`,'GET', null, token),
          apiCall(`${process.env.REACT_APP_API_URL}/promotions/installation-amounts`,'GET', null, token),
          apiCall(`${process.env.REACT_APP_API_URL}/promotions`, 'GET', null, token)
        ]);
        setRegions(regionsData);
        setInstallationAmounts(installationAmountsData);
        setPromotions(promotionsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [token]);

  // Renderizar contenido según tab activo
  const renderTabContent = () => {
    switch(activeTab) {
      case 'list':
        return <PromotionsList token={token} promotions={promotions} />;
      
      case 'promotions':
        return (
          <div className="tarifas-installation-container">
            <div className="tarifas-installation-section">
              <h3 className="tarifas-section-title">Crear Nueva Promoción</h3>
              <CreatePromotionWithRecaptcha 
                token={token} 
                installationAmounts={installationAmounts} 
              />
            </div>
            <div className="tarifas-installation-section">
              <h3 className="tarifas-section-title">Actualizar Promoción Existente</h3>
              <UpdatePromotionWithRecaptcha 
                token={token} 
                promotions={promotions} 
                installationAmounts={installationAmounts} 
              />
            </div>
            <div className="tarifas-installation-section">
              <h3 className="tarifas-section-title">Actualizar Monto de Instalación a Promoción</h3>
              <UpdateInstallationAmount 
                token={token} 
                promotions={promotions} 
                installationAmounts={installationAmounts} 
              />
            </div>
            {/* <div className="tarifas-installation-section">
              <h3 className="tarifas-section-title">Deshabilitar Promociones</h3>
              <DisablePromotions
                token={token}
                regions={regions}
                promotions={promotions}
              />
            </div>
            <div className="tarifas-installation-section">
              <h3 className="tarifas-section-title">Asignar Promociones</h3>
              <AssignPromotion 
                token={token} 
                regions={regions} 
                promotions={promotions} 
              />
            </div> */}
          </div>
        );
      
      case 'installation':
        return (
          <div className="tarifas-installation-container">
            <div className="tarifas-installation-section">
              <h3 className="tarifas-section-title">Crear Nuevo Monto de Instalación</h3>
              <CreateInstallationAmountWithRecaptcha token={token} />
            </div>
            <div className="tarifas-installation-section">
              <h3 className="tarifas-section-title">Modificar Monto de Instalación Existente</h3>
              <ModifyInstallationAmountWithRecaptcha 
                token={token} 
                installationAmounts={installationAmounts} 
              />
            </div>
            <div className="tarifas-installation-section">
              <h3 className="tarifas-section-title">Habilitar/Deshabilitar Monto de Instalación</h3>
              <ToggleInstallationAmount 
                token={token} 
                installationAmounts={installationAmounts} 
              />
            </div>
          </div>
        );
      
      default:
        return null;
    }
  };
  
  return (
    <div className="tarifas-container">
      <h1 className="tarifas-page-title">Promociones y Montos de Instalación</h1>
      
      <div className="tarifas-tabs-container">
        {tabs.map((tab) => (
          <button
            key={tab.value}
            className={`tarifas-tab ${activeTab === tab.value ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.value)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="tarifas-tab-content">
        {renderTabContent()}
      </div>
    </div>
  );
};

// HOC para Recaptcha
const CreatePromotionWithRecaptcha = withRecaptcha(CreatePromotion, 'create_promotion');
const UpdatePromotionWithRecaptcha = withRecaptcha(UpdatePromotion, 'update_promotion');
const CreateInstallationAmountWithRecaptcha = withRecaptcha(CreateInstallationAmount, 'create_installation_amount');
const ModifyInstallationAmountWithRecaptcha = withRecaptcha(ModifyInstallationAmount, 'modify_installation_amount');

export default Tarifas;