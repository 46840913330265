import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import withRecaptcha from '../../../HOC/withRecaptcha';
import './ComunasTarifas.css';

const ComunasTarifas = ({ onSubmitWithRecaptcha }) => {
  const { token } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState('add');
  const [regions, setRegions] = useState([]);
  const [communes, setCommunes] = useState([]);

  const [formData, setFormData] = useState({
    regionId: '',
    communeId: '',
    communeName: '',
    isActive: ''
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const regionsResponse = await fetch(`${process.env.REACT_APP_API_URL}/regions`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const regionsData = await regionsResponse.json();
        setRegions(regionsData);
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };

    if (token) fetchInitialData();
  }, [token]);

  useEffect(() => {
    const fetchCommunes = async () => {
      if (formData.regionId) {
        try {
          const communesResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/communes/region/${formData.regionId}`, 
            { headers: { 'Authorization': `Bearer ${token}` } }
          );
          const communesData = await communesResponse.json();
          setCommunes(communesData);
          
          setFormData(prev => ({
            ...prev,
            communeId: '',
            communeName: '',
          }));
        } catch (error) {
          console.error('Error fetching communes:', error);
          setCommunes([]);
        }
      }
    };

    fetchCommunes();
  }, [formData.regionId, token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const submitForm = async (event, recaptchaToken) => {
      let endpoint = '';
      let method = '';
      let body = {
        recaptchaToken,
        ...formData
      };

      switch (activeTab) {
        case 'add':
          endpoint = `${process.env.REACT_APP_API_URL}/communes/regions/${formData.regionId}/communes`;
          method = 'POST';
          body = { 
            commune_name: formData.communeName,
            recaptchaToken
          };
          break;
        case 'update':
          endpoint = `${process.env.REACT_APP_API_URL}/communes/${formData.communeId}`;
          method = 'PUT';
          body = { 
            commune_name: formData.communeName,
            recaptchaToken
          };
          break;
        case 'toggle':
          endpoint = `${process.env.REACT_APP_API_URL}/communes/${formData.communeId}/toggle-status`;
          method = 'PATCH';
          body = { 
            isActive: formData.isActive,
            recaptchaToken
          };
          break;
        default:
          return;
      }

      try {
        const response = await fetch(endpoint, {
          method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(body)
        });

        const result = await response.json();
        
        if (response.ok) {
          alert(result.message);
          // Recargar comunas
          if (formData.regionId) {
            const communesResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/communes/region/${formData.regionId}`, 
              { headers: { 'Authorization': `Bearer ${token}` } }
            );
            const communesData = await communesResponse.json();
            setCommunes(communesData);
          }
          
          // Resetear formulario
          setFormData(prev => ({
            ...prev,
            communeId: '',
            communeName: '',
            isActive: ''
          }));
        } else {
          throw new Error(result.message || 'Error en la operación');
        }
      } catch (error) {
        console.error('Error:', error);
        alert(error.message);
      }
    };

    await onSubmitWithRecaptcha(e, submitForm);
  };

  return (
    <div className="comunas-container">
      <h1 className="comunas-page-title">Gestión de Comunas</h1>
  
      <div className="comunas-tabs-container">
        {['add', 'update', 'toggle'].map(tab => (
          <button
            key={tab}
            className={`comunas-tab ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab === 'add' && 'Agregar Comuna'}
            {tab === 'update' && 'Actualizar Comuna'}
            {tab === 'toggle' && 'Cambiar Estado'}
          </button>
        ))}
      </div>
  
      <div className="comunas-tab-content">
        <div className="region-selector-container">
          <div className="region-selector">
            <label>Región</label>
            <select
              name="regionId"
              value={formData.regionId}
              onChange={handleInputChange}
              required
            >
              <option value="">Seleccione Región</option>
              {regions.map(region => (
                <option 
                  key={region.region_id} 
                  value={region.region_id}
                >
                  {region.region_name}
                </option>
              ))}
            </select>
          </div>
        </div>
  
        <div className="content-wrapper">
          <div className="communes-list-container">
            <h3>Comunas de la Región</h3>
            {formData.regionId ? (
              <div className="promotions-list-table-container">
                <table className="promotions-list-table">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {communes.map(commune => (
                      <tr 
                        key={commune.commune_id}
                        className={commune.is_active ? 'active' : 'inactive'}
                      >
                        <td>{commune.commune_name}</td>
                        <td>
                          {commune.is_active ? 'Activo' : 'Inactivo'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>Seleccione una región para ver sus comunas</p>
            )}
          </div>
  
          <div className="form-section">
            <form onSubmit={handleSubmit} className="comunas-tarifas-form">
              {/* Selector de Comuna (para update y toggle) */}
              {(activeTab === 'update' || activeTab === 'toggle') && formData.regionId && (
                <div className="comunas-tarifas-field-group">
                  <label>Comuna</label>
                  <select
                    className="comunas-tarifas-field"
                    name="communeId"
                    value={formData.communeId}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Seleccione Comuna</option>
                    {communes.map(commune => (
                      <option 
                        key={commune.commune_id} 
                        value={commune.commune_id}
                      >
                        {commune.commune_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
  
              {/* Input para nombre de Comuna */}
              {(activeTab === 'add' || activeTab === 'update') && (
                <div className="comunas-tarifas-field-group">
                  <label>Nombre de Comuna</label>
                  <input
                    className="comunas-tarifas-field"
                    type="text"
                    name="communeName"
                    value={formData.communeName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              )}
  
              {/* Selector de Estado */}
              {activeTab === 'toggle' && (
                <div className="comunas-tarifas-field-group">
                  <label>Estado</label>
                  <select
                    className="comunas-tarifas-field"
                    name="isActive"
                    value={formData.isActive}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Seleccione Estado</option>
                    <option value="1">Activo</option>
                    <option value="0">Inactivo</option>
                  </select>
                </div>
              )}
  
              <button 
                type="submit" 
                className="comunas-tarifas-submit-button"
              >
                {activeTab === 'add' && 'Agregar'}
                {activeTab === 'update' && 'Actualizar'}
                {activeTab === 'toggle' && 'Cambiar Estado'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRecaptcha(ComunasTarifas, 'comunas_action');
