import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import withRecaptcha from '../../../HOC/withRecaptcha';
import './ComunasTarifas.css'

// Utility function for API calls
const apiCall = async (url, method = 'GET', body = null, token = null) => {
  const headers = {
    'Content-Type': 'application/json',
    ...(token && { 'Authorization': `Bearer ${token}` })
  };
  const config = {
    method,
    headers,
    ...(body && { body: JSON.stringify(body) })
  };
  const response = await fetch(url, config);
  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Error de autenticación');
    } else {
      throw new Error('Fallo en la solicitud');
    }
  }
  return response.json();
};

const useToggleCard = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const toggle = () => setIsOpen(prev => !prev);
  return [isOpen, toggle];
};

// Card component
const Card = ({ title, children, isOpen, toggle }) => (
  <div className="card" onClick={toggle}>
    <h2>{title}</h2>
    {isOpen && <div onClick={(e) => e.stopPropagation()}>{children}</div>}
  </div>
);

//Obtener canales de venta
const ObtenerCanalesDeVenta = ({ channels }) => {
  const [selectedChannel, setSelectedChannel] = useState('');
  const handleChannelChange = (e) => {
    setSelectedChannel(e.target.value);
  };

  return (
    <select value={selectedChannel} onChange={handleChannelChange}>
      <option value="">Seleccione un canal de venta</option>
      {channels.map((channel) => (
        <option key={channel.sales_channel_id} value={channel.sales_channel_id}>
          {channel.channel_name}
        </option>
      ))}
    </select>
  );
};

const CrearCanalDeVenta = ({ token, onSubmitWithRecaptcha }) => {
  const [channelName, setChannelName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChannelNameChange = (e) => {
    setChannelName(e.target.value);
  };

  const handleCreateChannel = async (e) => {
    e.preventDefault();
    if (!channelName) {
      alert('Por favor complete todos los campos.');
      return;
    }

    const submitForm = async (event, recaptchaToken) => {
      setIsSubmitting(true);
      try {
        const result = await apiCall(
          `${process.env.REACT_APP_API_URL}/channels/create`,
          'POST',
          {
            salesChannelName: channelName,
            recaptchaToken
          },
          token
        );
        alert(result.message);
        setIsSubmitting(false);
        window.location.reload();
      } catch (error) {
        console.error('Error al crear canal de venta:', error);
        alert(error.message);
        setIsSubmitting(false);
      }
    };

    await onSubmitWithRecaptcha(e, submitForm);
  };

  return (
    <form className="comunas-tarifas-form" onSubmit={handleCreateChannel}>
      <div className="comunas-tarifas-field-group">
        <h3>Crear Canal de Venta</h3>
        <input 
          type="text" 
          className="comunas-tarifas-field"
          value={channelName} 
          onChange={handleChannelNameChange} 
          placeholder="Nombre del canal de venta"
        />
      </div>
      <button 
        type="submit" 
        className="comunas-tarifas-submit-button" 
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Creando...' : 'Crear Canal de Venta'}
      </button>
    </form>
  );
};
// Actualizar canal de venta
const ActualizarCanalDeVenta = ({ token, onSubmitWithRecaptcha }) => {
  const [selectedChannel, setSelectedChannel] = useState('');
  const [newChannelName, setNewChannelName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const result = await apiCall(`${process.env.REACT_APP_API_URL}/channels/all`, 'GET', null, token);
        setChannels(result);
      } catch (error) {
        console.error('Error al obtener canales de venta:', error);
        alert('Error al obtener canales de venta');
      }
    };

    fetchChannels();
  }, [token]);

  const handleChannelChange = (e) => {
    setSelectedChannel(e.target.value);
  };

  const handleChannelNameChange = (e) => {
    setNewChannelName(e.target.value);
  };

  const handleUpdateChannel = async (e) => {
    e.preventDefault();
    if (!selectedChannel || !newChannelName) {
      alert('Por favor complete todos los campos.');
      return;
    }

    const submitForm = async (event, recaptchaToken) => {
      setIsSubmitting(true);
      try {
        const result = await apiCall(
          `${process.env.REACT_APP_API_URL}/channels/${selectedChannel}`,
          'PUT',
          {
            salesChannelName: newChannelName,
            recaptchaToken
          },
          token
        );
        alert(result.message);
        setIsSubmitting(false);
        window.location.reload();
      } catch (error) {
        console.error('Error al actualizar canal de venta:', error);
        alert(error.message);
        setIsSubmitting(false);
      }
    };

    await onSubmitWithRecaptcha(e, submitForm);
  };

  return (
    <form className="comunas-tarifas-form" onSubmit={handleUpdateChannel}>
      <div className="comunas-tarifas-field-group">
        <h3>Actualizar Canal de Venta</h3>
        <select 
          className="comunas-tarifas-field"
          value={selectedChannel} 
          onChange={handleChannelChange}
        >
          <option value="">Seleccione un canal de venta</option>
          {channels.map((channel) => (
            <option key={channel.sales_channel_id} value={channel.sales_channel_id}>
              {channel.channel_name}
            </option>
          ))}
        </select>
      </div>
      <div className="comunas-tarifas-field-group">
        <input 
          type="text" 
          className="comunas-tarifas-field"
          value={newChannelName} 
          onChange={handleChannelNameChange} 
          placeholder="Nuevo nombre del canal"
        />
      </div>
      <button 
        type="submit" 
        className="comunas-tarifas-submit-button" 
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Actualizando...' : 'Actualizar Canal de Venta'}
      </button>
    </form>
  );
};

// Habilitar/Deshabilitar canal de venta
const ToggleCanalDeVentaStatus = ({ token }) => {
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState('');
  const [channelStatus, setChannelStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const channelsData = await apiCall(
          `${process.env.REACT_APP_API_URL}/channels/all`, 
          'GET', 
          null, 
          token
        );
        setChannels(channelsData);
      } catch (error) {
        console.error('Error al obtener los canales de venta:', error);
      }
    };

    if (token) {
      fetchChannels();
    }
  }, [token]);

  const handleChannelChange = (e) => {
    setSelectedChannel(e.target.value);
  };

  const handleStatusChange = (e) => {
    setChannelStatus(e.target.value);
  };

  const handleToggleStatus = async (e) => {
    e.preventDefault();
    if (!selectedChannel || channelStatus === '') {
      alert('Por favor seleccione un canal y su estado.');
      return;
    }
    setIsSubmitting(true);
    try {
      const result = await apiCall(
        `${process.env.REACT_APP_API_URL}/channels/${selectedChannel}/toggle-status`,
        'PUT',
        { is_active: parseInt(channelStatus) },
        token
      );
      alert(result.message);
      setIsSubmitting(false);
      window.location.reload();
    } catch (error) {
      console.error('Error al habilitar/deshabilitar canal de venta:', error);
      alert(error.message);
      setIsSubmitting(false);
    }
  };

  return (
    <form className="comunas-tarifas-form" onSubmit={handleToggleStatus}>
      <div className="comunas-tarifas-field-group">
        <h3>Habilitar/Deshabilitar Canal de Venta</h3>
        <select 
          className="comunas-tarifas-field"
          value={selectedChannel} 
          onChange={handleChannelChange}
        >
          <option value="">Seleccione un canal de venta</option>
          {channels.map((channel) => (
            <option 
              key={channel.sales_channel_id} 
              value={channel.sales_channel_id}
            >
              {channel.channel_name} ({channel.is_active ? 'Habilitado' : 'Deshabilitado'})
            </option>
          ))}
        </select>
      </div>
      <div className="comunas-tarifas-field-group">
        <select 
          className="comunas-tarifas-field"
          value={channelStatus} 
          onChange={handleStatusChange}
        >
          <option value="">Seleccione estado</option>
          <option value="1">Habilitado</option>
          <option value="0">Deshabilitado</option>
        </select>
      </div>
      <button 
        type="submit" 
        className="comunas-tarifas-submit-button" 
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Procesando...' : 'Habilitar/Deshabilitar Canal'}
      </button>
    </form>
  );
};

// Mantener los HOC de Recaptcha
const CrearCanalDeVentaWithRecaptcha = withRecaptcha(CrearCanalDeVenta, 'create_channel');
const ActualizarCanalDeVentaWithRecaptcha = withRecaptcha(ActualizarCanalDeVenta, 'update_channel');
// Main component
const CanalesDeVenta = () => {
  const { token } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState('crear');
  const [channels, setChannels] = useState([]);

  // Mover fetchData fuera del useEffect para que esté disponible en todo el componente
  const fetchData = async () => {
    if (!token) return;
    try {
      const channelsData = await apiCall(
        `${process.env.REACT_APP_API_URL}/channels`, 
        'GET', 
        null, 
        token
      );
      setChannels(channelsData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const renderTabContent = () => {
    switch(activeTab) {
      case 'crear':
        return <CrearCanalDeVentaWithRecaptcha 
          token={token} 
          onChannelCreated={() => {
            // Recargar canales después de crear
            fetchData();
          }} 
        />;
      case 'actualizar':
        return <ActualizarCanalDeVentaWithRecaptcha 
          token={token} 
          channels={channels}
        />;
      case 'estado':
        return <ToggleCanalDeVentaStatus 
          token={token} 
          onStatusChanged={() => {
            fetchData();
          }} 
        />;
      default:
        return null;
    }
  };

  return (
    <div className="comunas-container">
      <h1 className="comunas-page-title">Gestión de Canales de Venta</h1>
      
      <div className="comunas-tabs-container">
        {[
          { key: 'crear', label: 'Crear Canal' },
          { key: 'actualizar', label: 'Actualizar Canal' },
          { key: 'estado', label: 'Cambiar Estado' }
        ].map(tab => (
          <button
            key={tab.key}
            className={`comunas-tab ${activeTab === tab.key ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="comunas-tab-content">
        <div className="content-wrapper">
          <div className="promotions-list-table-container">
            <h3 className="tarifas-section-title">Listado de Canales de Venta</h3>
            <table className="promotions-list-table">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {channels.map((channel) => (
                  <tr key={channel.sales_channel_id}>
                    <td>{channel.channel_name}</td>
                    <td>
                      {channel.is_active ? 'Activo' : 'Inactivo'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="form-section">
            {renderTabContent()}
          </div>
        </div>
      </div>
    </div>
  );
};


export default CanalesDeVenta;